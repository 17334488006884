import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  wrapper: {
    padding: theme.spacing(10, 2),
    maxWidth: 972,
    margin: "0 auto",
  },
  sectionTitle: {
    marginBottom: theme.spacing(1),
  },
  paragraph: {
    display: "block",
    marginBottom: theme.spacing(4),
  },
}))

export default useStyles

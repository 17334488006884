import React from "react"
import YellowHeader from "../components/general/yellowHeader"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import useStyles from "./textPages.style"

const NotFoundPage = () => {
  const classes = useStyles()

  return (
    <Layout>
      <SEO title="404: Not found" />
      <YellowHeader title="NOT FOUND" />
      <div className={classes.wrapper}>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </div>
    </Layout>
  )
}

export default NotFoundPage

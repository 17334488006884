import React from "react"

import Typography from "@material-ui/core/Typography"

import useStyles from "./yellowHeader.style"

export default function YellowHeader({ title, icon }) {
  const classes = useStyles()

  return (
    <div className={`${classes.root}`}>
      <Typography
        className={classes.title}
        variant="h6"
        component="h1"
        color="inherit"
        align="center"
      >
        {title}&nbsp;
        <span role="img" aria-label="icon">
          {icon}
        </span>
      </Typography>
    </div>
  )
}

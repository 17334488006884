import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 76,
    padding: theme.spacing(10, 2),
    color: "#FFF",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#FFC400",
  },
  title: {
    maxWidth: 486,
  },
}))

export default useStyles
